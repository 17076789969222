import React, { Component } from 'react';
import './Home.css';
import InstList from '../../InstList/InstList';
import ProductList from '../../ProductList/ProductList';
import SectionAbout from '../../Sections/SectionAbout/SectionAbout';
import SectionDrNova from '../../Sections/SectionDrNova/SectionDrNova';
import SectionTestimonials from '../../Sections/SectionTestimonials/SectionTestimonials';
import ScrollToAnchor from '../../Tools/ScrollToAnchor';

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <ScrollToAnchor />
        <div className='container-fluid p-0 red' id='drNova'>
          <div className='container'>
            <SectionDrNova></SectionDrNova>
          </div>
        </div>

        <div className='container-fluid p-0 py-5 blue' id='about'>
          <div className='container'>
            <SectionAbout></SectionAbout>
          </div>
        </div>

        <div className='container-fluid p-0 py-5 orange' id='services'>
          <div className='container'>
            <ProductList></ProductList>
          </div>
        </div>

        <div className='container-fluid p-0 py-5 azure' id='testimonials'>
          <div className='container'>
            <SectionTestimonials></SectionTestimonials>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
