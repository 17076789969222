import React, { Component } from 'react';
import './SectionTestimonials.css';
import TESTIMONIALS from '../../../models/testimonials';

class SectionTestimonials extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className='white'>
          <h2 className='pb-3'>Dr. Nova's testimonials</h2>
          <p className='text-justify'>
            As beauty professional I believe that the best way to showcase the
            quality of our services is through the words of those who matter
            most - our clients. Testimonials are not just reviews; they are a
            reflection of the trust, satisfaction, and positive experiences our
            clients have with us. I take pride in the trust our clients have
            placed in us and invite you to explore their testimonials to
            discover why my services, products and courses are the top choice.
          </p>
          <p className='text-justify pb-3'>
            Let our happy clients guide you in making the right choice!
          </p>
        </div>
        <div id='carousel-testi' className='carousel slide pb-4'>
          <div className='carousel-indicators pb-3'>
            {TESTIMONIALS.map((_, i) => (
              <button
                type='button'
                data-bs-target='#carousel-testi'
                data-bs-slide-to={`${i}`}
                className={i === 0 ? 'active' : null}
                aria-current={i === 0 ? 'true' : null}
                aria-label={`Slide ${i}`}
                key={i}></button>
            ))}
          </div>
          <div className='carousel-inner white'>
            {TESTIMONIALS.map((testi, i) => (
              <div
                className={i === 0 ? 'carousel-item active' : 'carousel-item'}
                key={i}>
                <div className='row justify-content-center'>
                  <div className='col-lg-3 col-9 d-flex align-items-center justify-content-center'>
                    <img
                      src={`/img/testimonials/${testi.image}`}
                      alt={testi.alt}
                      className='w-100 pb-4'
                    />
                  </div>
                  <div className='col-lg-6 col-9'>
                    <h5 className='mb-3'>{testi.title}</h5>
                    <p className='text-justify'>{testi.text}</p>
                    <p className='text-end fst-italic'>{testi.clientName}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carousel-testi'
            data-bs-slide='prev'>
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'></span>
            <span className='visually-hidden'>Previous</span>
          </button>

          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carousel-testi'
            data-bs-slide='next'>
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default SectionTestimonials;
