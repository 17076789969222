import React, { Component } from 'react';
import './SectionAbout.css';
import CERTS from '../../../models/certs';
import XP_AND_EDUCATIONS from '../../../models/xp-and-educations';
const MAX_CERTS_PER_LINE = 5;

class SectionAbout extends Component {
  state = {};

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  render() {
    return (
      <div className='white'>
        <h2 className='pb-3'>About Dr. Nova</h2>
        <p className='text-justify pb-3'>
          I am dedicated beauty professional with a passion for enhancing
          natural beauty and boosting confidence. With over 5 years in the
          beauty industry, I’ve honed my skills and knowledge to provide
          top-notch services tailored to each unique client’s needs. My approach
          combines creativity, precision, and a deep understanding of the latest
          beauty trends to deliver results that make you look and feel absolute.
          I specialize in facial and body treatments like peels, mesotherapy,
          microneedling and much more. In addition, I am a master of cosmetic
          chemistry, which means I work only with the highest quality products
          and techniques. My passion is to design a one-of-a-kind experience
          that leaves every client feeling refreshed, and confident in their own
          body and skin. Your beauty journey with me goes beyond simple
          treatments; it’s about curating a memorable experience that makes you
          feel truly special. I'm creating a warm, welcoming environment where
          you can relax and trust that you’re in expert's hands. Whether you’re
          preparing for a special event or simply looking to refresh your look,
          I’m here to help you shine!
        </p>

        <h3 className='pb-3'>Experience and education</h3>
        <div id='ed-list'>
          {XP_AND_EDUCATIONS.map((xp, index) => (
            <div className='row pb-2 pb-sm-0' key={index}>
              <div className='col'>
                <div className='left'>
                  <div className='left col-year text-center ms-md-4'>
                    {xp.year ? xp.year : '\u00A0'}
                  </div>
                  <div className='left col-vr d-flex mx-md-5 d-none d-md-block d-lg-block'>
                    <div className='vr'>
                      <i className={this.getXpClasses(xp)}></i>
                    </div>
                  </div>
                  <div className='left col-place'>
                    <div className='place'>{xp.place}</div>
                    <div>{xp.title}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <h3 className='pb-3 pt-4 pt-sm-5'>Certifications</h3>
        {this.state.certsPerRow ? (
          <div
            id='xp-carousel'
            className='carousel slide'
            data-bs-ride='carousel'>
            <div className='carousel-inner'>
              {[...Array(this.getCertsPagesCount()).keys()].map((_, i) => (
                <div
                  className={i === 0 ? 'carousel-item active' : 'carousel-item'}
                  key={i}>
                  <div className='row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-xxl-5 gy-3 fade-in mb-2'>
                    {this.getCertsForPage(i).map((cert, index) => (
                      <div className='col' key={index}>
                        {cert && Object.keys(cert).length > 0 ? (
                          <figure className='cert-figure'>
                            <img
                              className='cert'
                              src={`/img/certs/${cert.image}`}
                              alt={cert.title}
                            />
                          </figure>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#xp-carousel'
              data-bs-slide='prev'>
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#xp-carousel'
              data-bs-slide='next'>
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  getXpClasses = (xp) =>
    `bi bi-xp ${xp.isDegree ? 'bi-mortarboard-fill' : 'bi-record-fill'} `;

  resize() {
    let width = document.querySelector('div.container').offsetWidth;
    let certsPerRow = 2;

    //md
    if (width >= 576 && width < 768) certsPerRow = 3;
    //lg
    else if (width >= 768 && width < 992) certsPerRow = 4;
    //xl or more
    else if (width >= 992) certsPerRow = 5;

    this.setState({ certsPerRow: certsPerRow });
  }

  getMaxCertsLines = () => Math.ceil(CERTS.length / MAX_CERTS_PER_LINE);
  getCertsPagesCount = () =>
    Math.ceil(
      CERTS.length / (this.state.certsPerRow * this.getMaxCertsLines())
    );
  getCertsForPage = (pageIndex) => {
    const certsPerRow = this.state.certsPerRow;
    const maxLines = this.getMaxCertsLines();
    let res = [];

    for (let line = 0; line < maxLines; line++) {
      for (let col = 0; col < certsPerRow; col++) {
        let index = pageIndex * certsPerRow + MAX_CERTS_PER_LINE * line + col;
        // console.log(index);

        if (index < CERTS.length && index < MAX_CERTS_PER_LINE * (line + 1)) {
          // console.log(CERTS[index].title);
          res.push(CERTS[index]);
        } else res.push({});
      }
    }
    // console.log(res);
    return res;
  };
}

export default SectionAbout;
