const QUESTIONS = [
  {
    question: 'How do I book an online appointment with Dr. Nova?',
    answer:
      'To book an appointment online, simply navigate to our "Book Appointment" page. Select the service you’re interested in, choose an available date and time, and provide your contact details. Once confirmed, you\'ll receive a booking confirmation by email or SMS.',
  },
  {
    question: 'What information do I need to provide to book an appointment?',
    answer:
      "You'll need to select the service, date, and time, and provide your full name, contact information (phone or email), and any special requests you may have. If you’re a new client, we may ask for additional information to personalize your experience.",
  },
  {
    question: 'How far in advance should I book my appointment?',
    answer:
      'We recommend booking as early as possible to secure your preferred time slot, especially during peak hours or weekends. You can see availability up to [30 days/other timeframe] in advance.',
  },
  {
    question: 'Can I reschedule or cancel my online appointment?',
    answer:
      'Yes, you can reschedule or cancel your appointment through the confirmation link sent to you or by logging into your account. Please note that cancellations or rescheduling within [24 hours] may incur a fee. Check our cancellation policy for more details.',
  },
  {
    question: 'Do I need to create an account to book an appointment?',
    answer:
      'No, creating an account is optional. However, registering allows you to view your appointment history, easily reschedule, and receive exclusive offers.',
  },
  {
    question: 'How will I know my appointment is confirmed?',
    answer:
      'After completing the booking process, you will receive a confirmation message via email or SMS. You may also get a reminder before your appointment.',
  },
  {
    question: 'Are online payments required to secure an appointment?',
    answer:
      'For certain services or peak times, an online deposit may be required to secure your booking. The remainder can be paid in person. Otherwise, you may pay after your service. Payment information will be provided during booking.',
  },
  {
    question:
      "What should I do if I didn't receive a confirmation for my booking?",
    answer:
      "If you haven't received a confirmation email or SMS within 10-15 minutes, please check your spam or junk folder. If it's still not there, contact us directly to confirm your booking status.",
  },
  {
    question: 'Is there a way to book a recurring appointment?',
    answer:
      'Yes, we offer the option to set up recurring appointments for regular services. When booking, select the “Recurring Appointment” option, and specify your preferred frequency (weekly, biweekly, monthly).',
  },
  {
    question:
      'How do I contact support if I have issues with the online booking?',
    answer:
      "If you encounter any issues, please reach out to us via phone or e-mail <a href='mailto:info@dr-nova.com'>info@dr-nova.com</a>. Our support team is available to assist you during business hours.",
  },
];
export default QUESTIONS;
