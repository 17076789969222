const COURSES = [
  {
    id: 'cosmetic-chemistry',
    title: 'Cosmetic chemistry from scratch',
    descriptionShort:
      'Explore cosmetic science, mastering ingredients and formulations to choose or create effective products - perfect for personal or professional use.',
    durationWeeks: 5,
    level: 'Beginner',
    image: 'cosmetic-chemistry.webp',
    alt: 'Cosmetic chemistry from scratch',
    description:
      'This course offers an in-depth exploration of the world of cosmetics, focusing on the ingredients, formulations, and the science behind how they work. You’ll discover how individual ingredients function, learn the art of creating effective formulas, and gain insight into the compositions of popular products. With a mix of up-to-date scientific data, real-world case studies, and practical tips, this course will empower you to choose the right products and even develop your own custom recipes. Perfect for anyone looking to build a professional understanding of cosmetics - whether for personal interest or a career in the industry.',
    price: 99.99,
  },
  {
    id: 'itec-preparation',
    title: 'ITEC Without Borders: Your International Certificate',
    descriptionShort:
      'Master essential skills and knowledge for foundational beauty training to confidently prepare for the ITEC exam.',
    durationWeeks: 10,
    level: 'Intermediate',
    image: 'itec.webp',
    alt: 'Author’s online course “ITEC Without Borders: Your International Certificate" (for Beauty therapy techniques level 3)',
    description:
      "This course equips you with essential knowledge and skills for the foundational theory and practical components of beauty school training, specifically tailored for those preparing for the ITEC exam. You'll gain insight into core methods and principles, making it easier to grasp and master the material throughout your training journey.",
    price: 799.99,
  },
];
export default COURSES;
