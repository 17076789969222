const TESTIMONIALS = [
  {
    title: 'Online beauty consultation',
    text: "\"I recently had an online beauty consultation, and I couldn't be happier with the experience! The beautician was incredibly knowledgeable and took the time to understand my skin concerns and beauty goals. She recommended products and treatments that were perfectly tailored to my needs, and I've already started to see amazing results. The consultation was convenient and personalized, making me feel confident and well-cared for. I highly recommend Dr. Nova's services to anyone looking for expert advice and a boost in their beauty routine!\"",
    image: 'online-consultation-sm.jpg',
    alt: 'Online beauty consultation',
    clientName: 'Sophia Reynolds',
  },
  {
    title: 'The Web Course Transformed My Career!',
    text: '"Taking the \'Cosmetic chemistry from scratch\' web course was a game-changer for my beauty career. The flexible online format allowed me to learn at my own pace, while the practical tutorials gave me skills I could apply immediately. Since completing the course, my client satisfaction has increased, and my bookings have grown. The certification also helped me stand out in the competitive beauty industry. Highly recommend!"',
    image: 'chem-course-sm.jpg',
    alt: 'Cosmetic chemistry from scratch',
    clientName: 'Maya Thompson',
  },
  {
    title: 'The Subscription is Worth Every Penny!',
    text: '"Subscribing to the [Name of Subscription] has been one of the best investments for my beauty business. The monthly access to exclusive tutorials, industry insights, and updated techniques has kept me ahead of trends. I love the fact that I can continuously improve my skills and offer my clients the latest in beauty treatments. The subscription also offers fantastic discounts on products, which has helped me save on high-quality supplies. It\'s a complete package-knowledge, savings, and support. I highly recommend it to any beauty professional who wants to stay competitive and keep growing!"',
    image: 'subscription-sm.jpg',
    alt: 'Dr. Nova subscription',
    clientName: 'Isabella Garcia',
  },
];
export default TESTIMONIALS;
