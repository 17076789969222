const CERTS = [
  {
    title: 'Specialist degree - Medical Doctor',
    image: 'cert-1-diploma.jpg',
  },
  {
    title: 'ITEC - Level 3 Diploma in Beauty Therapy Techniques',
    image: 'cert-2-itec.jpg',
  },
  {
    title: 'Endospheres Specialist Training Course certificate',
    image: 'cert-endospheres.jpg',
  },
  {
    title: 'Ultrasonic facial cleansing certificate',
    image: 'cert-ultrasonic-facial.jpg',
  },
  {
    title: 'Waxing Expert certificate',
    image: 'cert-waxing-expert.jpg',
  },
  {
    title: 'Face and Neck massage certificate',
    image: 'cert-face-and-neck-massage.jpg',
  },
  {
    title: 'Body SPA programs and sugar liposuction certificate',
    image: 'cert-body-spa.jpg',
  },
  {
    title: 'Sugaring Expert certificate',
    image: 'cert-sugaring-expert.jpg',
  },
  {
    title: 'Eyelash Lamination certificate',
    image: 'cert-eyelash-lamination.jpg',
  },
  {
    title: 'Masks Course certificate',
    image: 'cert-masks.jpg',
  },
  {
    title: "CMed Aesthetics' products certificate",
    image: 'cert-CMed-products.jpg',
  },
  {
    title: 'Ingrown Hairs and Inflammation certificate',
    image: 'cert-hairs-and-inflammation.jpg',
  },
  {
    title: 'Skin Diseases certificate',
    image: 'cert-skin-diseases.jpg',
  },
  {
    title: 'FAT DISSOLVING Training Course certificate',
    image: 'cert-fat-dissolving.jpg',
  },
  {
    title: 'MESOTHERAPY Training Course certificate',
    image: 'cert-mesotherapy.jpg',
  },
];
export default CERTS;
