import React, { Component } from 'react';

class Product extends Component {
  render() {
    let prod = this.props.product;
    return (
      <div className='product'>
        <a href={`/products/${prod.id}`}>
          <img src={`/img/products/${prod.image}`} alt={prod.alt} />
        </a>
        <h5 className={prod.title.length < 28 ? 'heavy-line' : null}>
          {prod.title}
        </h5>
        <p>{prod.price}</p>
        {prod.id === 'online-consultation' ? (
          <a href='' data-bs-toggle='modal' data-bs-target='#onlineBooking'>
            <button className='add-to-cart'>Book online</button>
          </a>
        ) : (
          <button className='add-to-cart' data-id={prod.id}>
            Add to Cart
          </button>
        )}
      </div>
    );
  }
}

export default Product;
