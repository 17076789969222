import React, { Component } from 'react';
import { InlineWidget } from 'react-calendly';
import './Header.css';
import Email from '../Icons/Email';
import WhatsApp from '../Icons/WhatsApp';
import Telegram from '../Icons/Telegram';
import Instagram from '../Icons/Instagram';
import PhoneLink from '../Links/PhoneLink';
import { NavLink } from 'react-router-dom';

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='container-fluid p-0' id='menu'>
          <div className='container'>
            <nav className='navbar navbar-expand-lg'>
              <div className='container-fluid p-0'>
                <button
                  className='navbar-toggler'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarSupportedContent'
                  aria-controls='navbarSupportedContent'
                  aria-expanded='false'
                  aria-label='Toggle navigation'>
                  <span className='navbar-toggler-icon'></span>
                </button>
                <div className='d-sm-block d-md-block d-lg-none contacts-sm'>
                  <span className='pe-3'>
                    <PhoneLink />
                  </span>
                  <Email />
                  <WhatsApp />
                  <Telegram />
                  <Instagram />
                </div>
                <div
                  className='collapse navbar-collapse'
                  id='navbarSupportedContent'>
                  <ul className='navbar-nav mb-2 mb-lg-0 w-100 justify-content-between'>
                    <li className='nav-item'>
                      <NavLink to='/' className='nav-link'>
                        Home
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' href='/#about'>
                        About
                      </a>
                    </li>
                    {/* <li className='nav-item dropdown'>
                      <a
                        className='nav-link dropdown-toggle'
                        href='#'
                        role='button'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'>
                        Services
                      </a>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Action
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Another action
                          </a>
                        </li>
                        <li>
                          <hr className='dropdown-divider' />
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Something else here
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Something else here
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Something else here
                          </a>
                        </li>
                      </ul>
                    </li> */}
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        href=''
                        data-bs-toggle='modal'
                        data-bs-target='#onlineBooking'>
                        Book online
                      </a>
                    </li>
                    <li className='nav-item'>
                      <NavLink className='nav-link' to='/courses'>
                        Courses
                      </NavLink>
                    </li>
                    {/* <li className='nav-item'>
                      <a className='nav-link' href='/blog'>
                        Beauty blog{' '}
                      </a>
                    </li> */}
                    <li className='nav-item d-none d-lg-block contacts-lg'>
                      <span className='pe-3'>
                        <PhoneLink />
                      </span>
                      <Email />
                      <WhatsApp />
                      <Telegram />
                      <Instagram />
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>

          <div className='modal' tabIndex='-1' id='onlineBooking'>
            <div className='modal-dialog modal-dialog-centered'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Book a call with Dr. Nova</h5>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'></button>
                </div>
                <div className='modal-body p-0 rounded-bottom'>
                  <InlineWidget url='https://calendly.com/silu1922/online-consultation?hide_gdpr_banner=1' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
