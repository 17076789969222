import React, { Component } from 'react';
import './Footer.css';
import Email from '../Icons/Email';
import Phone from '../Icons/Phone';
import WhatsApp from '../Icons/WhatsApp';
import Telegram from '../Icons/Telegram';
import Instagram from '../Icons/Instagram';
import PhoneLink from '../Links/PhoneLink';
import EmailLink from '../Links/EmailLink';

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='container-fluid p-0 py-5 black'>
          <div className='container'>
            <div className='white footer'>
              <div className='row pb-2 pb-sm-0 row-cols-1 row-cols-md-4 gy-5'>
                <div className='col'>
                  <h3 className='pb-3'>
                    <a href='/' className='no-line'>
                      Dr. Nova
                    </a>
                  </h3>
                  <p className='pe-3 fs-6'>
                    <PhoneLink />
                  </p>
                  <p className='pe-3 fs-6'>
                    <EmailLink />
                  </p>
                  <p>
                    <Phone />
                    <Email />
                    <WhatsApp />
                    <Telegram />
                    <Instagram />
                  </p>
                  <div className='pt-3'>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Email address'
                        aria-label='Email address'
                        aria-describedby='btn-subscribe'
                      />
                      <button
                        className='btn btn-outline-secondary'
                        type='button'
                        id='btn-subscribe'>
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col d-none d-md-block'></div>
                <div className='col'>
                  <h6 className='pb-3'>Quick Links</h6>
                  <p>
                    <a
                      href=''
                      data-bs-toggle='modal'
                      data-bs-target='#onlineBooking'>
                      Book online consultation
                    </a>
                  </p>
                  <p>
                    <a href='/'>Home</a>
                  </p>
                  <p>
                    <a href='/#services'>Services</a>
                  </p>
                  <p>
                    <a href='/courses'>Courses</a>
                  </p>
                </div>
                <div className='col'>
                  <h6 className='pb-3'>Explore more</h6>
                  <p>
                    <a href='/faq'>FAQ</a>
                  </p>
                  <p>
                    <a href='/#testimonials'>Testimonials</a>
                  </p>
                  <p>
                    <a href='/blog'>Beauty blog</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='vcard' style={{ display: 'none' }}>
            <span className='fn'>Kristina Smirnova</span>
            <span className='org'>Dr. Nova</span>
            <span className='category'>Beauty</span>
            <a href='mailto:info@dr-nova.com' className='email'>
              info@dr-nova.com
            </a>
            <a href='https://dr-nova.com' className='url'>
              https://dr-nova.com
            </a>
            <a href='tel:+35699714077' className='tel'>
              +35699714077
            </a>
            <span className='nickname'>kristifina</span>
            <div className='adr'>
              <span className='street-address'></span>
              <span className='locality'>https://dr-nova.com</span>
              <span className='region'>Europe</span>
            </div>
            <div className='note'>
              Passionate and dedicated beauty professional with more than 5
              years of experience in the industry
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
