import React, { Component } from 'react';
import './SectionDrNova.css';
import REGALS from '../../../models/regals';

class SectionDrNova extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className='row mt-5'>
          <div className='col-sm-12 col-lg-6 align-self-center'>
            <div className='card mb-5 bg-transparent info white'>
              <div className='card-body'>
                <h5 className='card-title mb-3 text-center'>Dr. Nova</h5>
                <p className='card-text fs-3 mb-3 text-center'>
                  Smart beauty & health
                </p>
                <div className='dr-nova card-text'>
                  <p className='text-justify mb-4'>
                    Passionate and dedicated beauty professional with more than
                    5 years of experience in the industry. My mission is to
                    empower you to feel confident, beautiful, and uniquely you
                    through personalized beauty services tailored to your needs.
                  </p>
                  <div className='regal-list'>
                    {REGALS.map((regal, index) => (
                      <div className='regal-block' key={index}>
                        <div className='regal-img me-3 ms-lg-3'>
                          <img
                            src={`/img/icons/${regal.icon}`}
                            alt={regal.title}
                            className={regal.extraImgClass}
                          />
                        </div>
                        <div className='regal-text'>{regal.title}</div>
                      </div>
                    ))}
                  </div>
                  <p className='text-justify mt-4'>
                    I am ready to share the secrets of youth with you. Here you
                    will learn a lot of exciting things about how to preserve
                    your attractiveness for many years! Join me, and together we
                    will make this world even more beautiful!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-5 text-end align-self-end d-none d-lg-block'>
            <img
              src='/img/kris.png'
              className='img-fluid rounded-start'
              alt='Dr. Nova'
            />
            {/* <div className='parallax float-right'></div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SectionDrNova;
