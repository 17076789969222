import React, { Component } from 'react';
import QUESTIONS from '../../../models/questions';
import Question from './Question/Question';
import PageTitle from '../../Common/PageTitle';

class FAQ extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='container my-5'>
          <div className='row mb-3 white'>
            <div className='col'>
              <PageTitle title='Frequently Asked Questions' />
            </div>
          </div>
          <div className='accordion'>
            {QUESTIONS.map((question, index) => (
              <Question index={index} question={question} />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FAQ;
