const REGALS = [
  {
    title: 'Doctor with honored diploma',
    icon: 'ico-doctor.png',
  },
  {
    title: 'Cosmetologist-Aesthetician, 4th degree',
    icon: 'ico-grad.png',
  },
  {
    title: 'Beauty specialist 3 level [ITEC certificated]',
    icon: 'ico-itec.png',
  },
  {
    title: 'Endosphere therapy specialist',
    icon: 'ico-endosphere.png',
  },
  {
    title: 'Bioretherapy professional [chemical peeling BioRePeelCl3]',
    icon: 'ico-peeling.png',
  },
  {
    title: 'Mesotherapist [face & body + lipolytics]',
    icon: 'ico-mesotherapy.png',
    extraImgClass: 'left-8',
  },
  {
    title: 'Ultrasonic facial cleansing specialist',
    icon: 'ico-ultrasound.png',
    extraImgClass: 'left2',
  },
  {
    title: 'Professional piercer',
    icon: 'ico-piercing.png',
    extraImgClass: 'left2',
  },
];
export default REGALS;
