import React, { Component } from 'react';
import './InstList.css';

class InstList extends Component {
  render() {
    return (
      <React.Fragment>
        <section id='instagram-feed' className='section fade-in'>
          <h3 className='white pb-3'>Latest from Instagram</h3>
          <div className='instagram-feed'>
            <div className='instagram-post'>
              <img
                src='/img/inst1.webp'
                alt='Close-up of a glittery eyeshadow look'
                width='200'
                height='200'
              />
              <div className='caption'>
                Sparkle and shine with our new glitter collection! ✨
                #GlamourGlowMakeup
              </div>
            </div>
            <div className='instagram-post'>
              <img
                src='/img/inst2.webp'
                alt='Before and after skincare routine results'
                width='200'
                height='200'
              />
              <div className='caption'>
                Real results from our 30-day skincare challenge! 🌟 #GlowUp
              </div>
            </div>
            <div className='instagram-post'>
              <img
                src='/img/inst3.webp'
                alt='Colorful array of nail polish bottles'
                width='200'
                height='200'
              />
              <div className='caption'>
                New season, new colors! Which one is your favorite? 💅 #NailArt
              </div>
            </div>
            <div className='instagram-post'>
              <img
                src='/img/inst1.webp'
                alt='Close-up of a glittery eyeshadow look'
                width='200'
                height='200'
              />
              <div className='caption'>
                Sparkle and shine with our new glitter collection! ✨
                #GlamourGlowMakeup
              </div>
            </div>
            <div className='instagram-post'>
              <img
                src='/img/inst2.webp'
                alt='Before and after skincare routine results'
                width='200'
                height='200'
              />
              <div className='caption'>
                Real results from our 30-day skincare challenge! 🌟 #GlowUp
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default InstList;
