const PRODUCTS = [
  {
    id: 'online-consultation',
    title: 'First online consultation',
    image: 'online-free.webp',
    alt: 'Online beauty consultation',
    price: 'FREE',
  },
  {
    id: 'online-consultation-30',
    title: '30 minutes online consultation',
    image: 'online-30.webp',
    alt: 'Online beauty consultation',
    price: '9.99',
  },
  {
    id: 'online-consultation-60',
    title: '60 minutes online consultation',
    image: 'online-60.webp',
    alt: 'Online beauty consultation',
    price: '17.99',
  },
  // {
  //   id: 'cosmetic-chemistry',
  //   title: 'Cosmetic chemistry from scratch',
  //   image: 'cosmetic-chemistry.jpeg',
  //   alt: 'Cosmetic chemistry from scratch',
  //   description:
  //     'This course offers an in-depth exploration of the world of cosmetics, focusing on the ingredients, formulations, and the science behind how they work. You’ll discover how individual ingredients function, learn the art of creating effective formulas, and gain insight into the compositions of popular products. With a mix of up-to-date scientific data, real-world case studies, and practical tips, this course will empower you to choose the right products and even develop your own custom recipes. Perfect for anyone looking to build a professional understanding of cosmetics — whether for personal interest or a career in the industry.',
  //   price: 99.99,
  // },
  // {
  //   id: 'itec-preparation',
  //   title: 'ITEC Without Borders: Your International Certificate',
  //   image: 'itec2.webp',
  //   alt: 'Author’s online course “ITEC Without Borders: Your International Certificate" (for Beauty therapy techniques level 3)',
  //   price: 799.99,
  // },
  // {
  //   id: 14,
  //   title: 'Velvet Matte Lipstick',
  //   image: 'lipstick.webp',
  //   alt: 'Velvet Matte Lipstick',
  //   price: 19.99,
  // },
  // {
  //   id: 15,
  //   title: 'Soft Focus Foundation',
  //   image: 'foundation.webp',
  //   alt: 'Soft Focus Foundation',
  //   price: 29.99,
  // },
];
export default PRODUCTS;
