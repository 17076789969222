import React, { Component } from 'react';

class Question extends Component {
  state = { collapseId: `collapse${this.props.index}` };
  constructor(props) {
    super(props);
    this.refDivAnswer = React.createRef();
  }
  componentDidMount() {
    this.refDivAnswer.current.innerHTML = `A: ${this.props.question.answer}`;
  }
  render() {
    return (
      <div className='accordion-item'>
        <h2 className='accordion-header'>
          <button
            className='accordion-button collapsed'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target={`#${this.state.collapseId}`}
            aria-expanded='false'
            aria-controls={this.state.collapseId}>
            Q: {this.props.question.question}
          </button>
        </h2>
        <div id={this.state.collapseId} className='accordion-collapse collapse'>
          <div className='accordion-body' ref={this.refDivAnswer}></div>
        </div>
      </div>
    );
  }
}

export default Question;
