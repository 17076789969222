import React, { Component } from 'react';
import './Products.css';

class Products extends Component {
  render() {
    return (
      <React.Fragment>
        <main>
          <h2>Our Beauty Products</h2>
          <p>
            Discover our curated selection of premium beauty products to enhance
            your natural glow:
          </p>

          <div className='product-grid'>
            <div className='product-card'>
              <img
                src='/img/prod1.webp'
                alt='Hydrating Moisturizer'
                className='product-image'
                width='250'
                height='200'
              />
              <div className='product-info'>
                <h3 className='product-title'>Hydrating Moisturizer</h3>
                <p>Nourish your skin with our deeply hydrating formula.</p>
                <p className='product-price'>$29.99</p>
                <button
                  className='add-to-cart'
                  data-id='1'
                  data-name='Hydrating Moisturizer'
                  data-price='29.99'>
                  Add to Cart
                </button>
              </div>
            </div>

            <div className='product-card'>
              <img
                src='/img/prod2.webp'
                alt='Vitamin C Serum'
                className='product-image'
                width='250'
                height='200'
              />
              <div className='product-info'>
                <h3 className='product-title'>Vitamin C Serum</h3>
                <p>
                  Brighten and even out your skin tone with our potent serum.
                </p>
                <p className='product-price'>$39.99</p>
                <button
                  className='add-to-cart'
                  data-id='2'
                  data-name='Vitamin C Serum'
                  data-price='39.99'>
                  Add to Cart
                </button>
              </div>
            </div>

            <div className='product-card'>
              <img
                src='/img/prod3.webp'
                alt='Volumizing Mascara'
                className='product-image'
                width='250'
                height='200'
              />
              <div className='product-info'>
                <h3 className='product-title'>Volumizing Mascara</h3>
                <p>
                  Achieve lush, voluminous lashes with our signature mascara.
                </p>
                <p className='product-price'>$24.99</p>
                <button
                  className='add-to-cart'
                  data-id='3'
                  data-name='Volumizing Mascara'
                  data-price='24.99'>
                  Add to Cart
                </button>
              </div>
            </div>

            <div className='product-card'>
              <img
                src='/img/prod4.webp'
                alt='Matte Lipstick'
                className='product-image'
                width='250'
                height='200'
              />
              <div className='product-info'>
                <h3 className='product-title'>Matte Lipstick</h3>
                <p>
                  Long-lasting, creamy matte lipstick in a range of stunning
                  shades.
                </p>
                <p className='product-price'>$19.99</p>
                <button
                  className='add-to-cart'
                  data-id='4'
                  data-name='Matte Lipstick'
                  data-price='19.99'>
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Products;
