import React from 'react';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from './components/Header/Header';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './components/Pages/Home/Home';
import Courses from './components/Pages/Courses/Courses';
import Products from './components/Pages/Products/Products';
import Contacts from './components/Pages/Contacts/Contacts';
import Footer from './components/Footer/Footer';
import FAQ from './components/Pages/FAQ/FAQ';

function App() {
  return (
    <React.Fragment>
      <Header></Header>
      <Routes>
        <Route path='index.html' element={<Navigate to='/' />} />
        <Route exact path='/' element={<Home />} />
        <Route exact path='/courses' element={<Courses />} />
        <Route exact path='/products' element={<Products />} />
        <Route exact path='/contacts' element={<Contacts />} />
        <Route exact path='/faq' element={<FAQ />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
      <Footer></Footer>
    </React.Fragment>
  );
}

export default App;
