import React, { Component } from 'react';

class Course extends Component {
  render() {
    let course = this.props.course;
    return (
      <div className='col mt-4'>
        <div className='course-card'>
          <img
            src={`/img/courses/${course.image}`}
            alt={course.alt}
            className='course-image'
          />
          <div className='course-content'>
            <h5>{course.title}</h5>
            <p className='course-description'>{course.descriptionShort}</p>
            <div className='course-details'>
              <span>Duration: {course.durationWeeks} weeks</span>
              <span>Level: {course.level}</span>
            </div>
            <button className='add-to-cart' data-id={course.id}>
              Learn More
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Course;
