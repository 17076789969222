const XP_AND_EDUCATIONS = [
  {
    place: 'Beauty salon "StyleX"',
    title: 'Cosmetologist',
    year: 2024,
  },
  {
    place: 'Cosmetology Course',
    title: 'Cosmetic Chemist',
    isDegree: true,
  },
  {
    place: 'Beauty School Latvia',
    title: 'ITEC - Beauty specialist (3 level)',
    isDegree: true,
  },
  {
    place: 'SPA salon  "Healing Touch"',
    title: 'Professional Massage Therapist',
    year: 2023,
  },

  {
    place: 'Institute of Professional Competencies',
    title: 'Beautician of 4th category',
    isDegree: true,
  },
  {
    place: 'Beauty center "Harmony"',
    title: 'Consultant',
    year: 2022,
  },
  {
    place: 'MRSU',
    title: 'Doctor (General practitioner)',
    year: '2016 - 2022',
    isDegree: true,
  },
];
export default XP_AND_EDUCATIONS;
