import React, { Component } from 'react';
import './Contacts.css';

class Contacts extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='container mt-5'>
          <div className='row mb-3'>
            <div className='col'>
              <h2>Contact Us</h2>
            </div>
          </div>
          <div className='row row-cols-2 row-cols-md-4 row-cols-lg-5 g-lg-5 gy-3'>
            <div className='col text-center'>
              <div className='card shadow '>
                <div className='card-body'>
                  <i
                    className='bi bi-telephone'
                    width='32'
                    height='32'
                    fill='blue'></i>
                  <h5 className='card-title'>Phone</h5>
                </div>
              </div>
            </div>
            <div className='col text-center'>
              <div className='card shadow '>
                <div className='card-body'>
                  <i
                    className='bi bi-whatsapp'
                    width='32'
                    height='32'
                    fill='blue'></i>
                  <h5 className='card-title'>WhatsApp</h5>
                </div>
              </div>
            </div>
            <div className='col text-center'>
              <div className='card shadow '>
                <div className='card-body'>
                  <i
                    className='bi bi-instagram'
                    width='32'
                    height='32'
                    fill='blue'></i>
                  <h5 className='card-title'>Instagram</h5>
                </div>
              </div>
            </div>
            <div className='col text-center'>
              <div className='card shadow '>
                <div className='card-body'>
                  <i
                    className='bi bi-telegram'
                    width='32'
                    height='32'
                    fill='blue'></i>
                  <h5 className='card-title'>Telegram</h5>
                </div>
              </div>
            </div>
            <div className='col text-center'>
              <div className='card shadow '>
                <div className='card-body'>
                  <i
                    className='bi bi-envelope'
                    width='32'
                    height='32'
                    fill='blue'></i>
                  <h5 className='card-title'>E-mail</h5>
                </div>
              </div>
            </div>
          </div>
          <div className='row gx-5 mt-5'>
            <div className='col'>
              <div className='card shadow '>
                <div className='card-body'>
                  <h5 className='card-title'>Send me a message</h5>
                  <h6 className='card-subtitle mb-4 text-body-secondary'>
                    Fill the form to send me a message
                  </h6>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingInput'
                      placeholder='Andrey Smirnov'
                    />
                    <label for='floatingInput'>Name</label>
                  </div>
                  <div className='form-floating mb-3'>
                    <input
                      type='email'
                      className='form-control'
                      id='floatingInput'
                      placeholder='name@example.com'
                    />
                    <label for='floatingInput'>Email address</label>
                  </div>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingInput'
                      placeholder='Question'
                    />
                    <label for='floatingInput'>Subject</label>
                  </div>
                  <div className='mb-3'>
                    <label for='message' className='form-label'>
                      Message
                    </label>
                    <textarea
                      className='form-control'
                      id='message'
                      rows='3'></textarea>
                  </div>
                  <button type='button' className='btn btn-primary'>
                    Send message
                  </button>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='contact-info'>
                <h3>Get in touch</h3>
                <div className='contact-method'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path d='M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z' />
                  </svg>
                  <p>123 Beauty Lane, Spa City, BC 12345</p>
                </div>
                <div className='contact-method'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z' />
                  </svg>
                  <p>info@beautyspa.com</p>
                </div>
                <div className='contact-method'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path d='M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z' />
                  </svg>
                  <p>(555) 123-4567</p>
                </div>
                <div id='map'></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contacts;
